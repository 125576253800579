<template>
  <transition name="modal">
    <div>
      <div class="overlay" @click.self="close()">
        <div class="modal">
          <div class="flex justify-end">
            <div class="cursor-pointer" @click="close">
              <Close />
            </div>
          </div>
          <div class="content">
            <img src="../../assets/tia/tia-no-desc.png" alt="logo" class="logo-img inline-block w-36" /><br /><br />
            Cek email kamu di <strong>{{ email }}</strong> dan ikuti petunjuk untuk membuat kata sandi baru.
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  components: {
    Close: () => import(/* webpackChunkName: "icon-close" */ '@/components/icons/Close')
  },
  props: ['email'],
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.5s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
.modal {
  width: 500px;
  height: 200px;
  margin: 0px auto 0px;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px 3px;
  transition: all 0.2s ease-in;
  position: relative;
  text-align: center;
  @media screen and (max-width: 768px) {
    top: 30%;
    margin: 0 20px;
    overflow-y: scroll;
    width: 100%;
  }
}
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-leave-active {
  opacity: 0;
  transition: all 0.2s step-end;
}

.fadeIn-enter .modal,
.fadeIn-leave-active.modal {
  transform: scale(1.1);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000094;
  z-index: 999;
  transition: opacity 0.2s ease;
  @media screen and (max-width: 768px) {
    align-items: unset;
  }
}
</style>
